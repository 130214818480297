










































































































































import { Component, Vue } from 'vue-property-decorator';
import ValidatedForm from '@/utilities/validation/validatedform';
import FormField from '@/utilities/validation/formfield';
import emailValidator from '@/utilities/validators/email';
import VButton from '@/components/VButton.vue';
import VDropdown from '@/components/VDropdown.vue';
import * as types from '@/store/main/types';
import { Action } from 'vuex-class';

@Component({ components: { VButton, VDropdown } })
export default class TrialSupplier extends Vue {

  @Action('contactSupplier', { namespace: 'main' }) private contactSupplier!: types.ContactSupplierAction;

  private trialForm = new ValidatedForm({
    name: new FormField((value: any, dirty: boolean) => value !== '', ''),
    lastName: new FormField((value: any, dirty: boolean) => value !== '', ''),
    companyName: new FormField((value: any, dirty: boolean) => value !== '', ''),
    email: new FormField(emailValidator, ''),
    reason: new FormField((value: any, dirty: boolean) => value !== '', ''),
    preference: new FormField((value: any, dirty: boolean) => value !== '', ''),
  });

  private reasonOptions = [
    { text: 'Wij zijn software leverancier in de uitzendbranche', value: 'software leverancier in de uitzendbranche' },
    { text: 'Wij zijn een uitzendbureau met eigen software', value: 'uitzendbureau met eigen software' },
    { text: 'Wij zijn een loonadministratiebedrijf', value: 'loonadministratiebedrijf' },
  ];

  private preferenceOptions = [
    { text: 'API Customer Per Call', value: 'API Customer Per Call' },
    { text: 'API Json Uur', value: 'API Json' },
    { text: 'API Json Maand', value: 'API Json Maand' },
  ];

  private submitting = false;

  private get trialFormValid() {
    return this.trialForm.fieldValidity();
  }

  private submit() {
    if (this.trialForm.formValid()) {
      this.submitting = true;
      this.contactSupplier({
        name: this.trialForm.fields.name.value,
        lastName: this.trialForm.fields.lastName.value,
        companyName: this.trialForm.fields.companyName.value,
        email: this.trialForm.fields.email.value,
        preference: this.trialForm.fields.preference.value,
        reason: this.trialForm.fields.reason.value,
      })
      .then(() => {
        (this.$refs['trial-supplier-contact-modal'] as any).show();
      })
      .catch(() => {
        (this.$refs['trial-supplier-error-modal'] as any).show();
      })
      .finally(() => {
        this.submitting = false;
      });
    }
  }
}
